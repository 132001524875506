import {type httpError } from '@/api/http_types';

export const useErrorStore = defineStore('errorStore', {
  state: () => ({
    clear_time: 3000,
    endAt : 0,
    result: {} as httpError,
  }),
  actions: {
    setResult(value: httpError) {
      console.log('setResult', value)
      this.result = value
      this.endAt = Date.now() + this.clear_time
    },
    setEndAtZero() {
      this.endAt = 0
    }
  },
})