<template>

  <div class="text-center">
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      rounded="xl"
      position="sticky"
      max-width="900"
      min-width="500"
      location="center"
    >
     
      <h3 class="font-bold text-xl">编码: {{ error?.result?.data?.code || error?.result?.code }}</h3>
      <p>消息: {{error?.result?.data?.message || error?.result?.message }}.</p>
      <p><span class="text-red-200">说明: {{ error?.result?.explanation as any }}</span>.</p>
      <template v-slot:actions>
        <v-btn
          color="pink"
          @click="Close"
          icon
          class="shadow-lg hover:shadow-xl transition duration-500 ease-in-out"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script setup lang="ts">
  const snackbar = ref(false)
  const timeout = ref(1500)
  const error = useErrorStore()


  const Close = () => {
    error.setEndAtZero()
    timeout.value = 0
  }

  // 渲染错误
  const renderError = () => {
    if (error.endAt !== 0) {
      snackbar.value = true
      if (error.endAt < Date.now()) {
        snackbar.value = true
        error.setEndAtZero()
      }else{
        timeout.value = error.endAt - Date.now()
      }
    }
    requestAnimationFrame(renderError)
  }

  if( process.client ){
    renderError()
  }

</script>