// import this after install `@mdi/font` package
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'

import { pl, zhHans } from 'vuetify/locale'

const LightTheme = {
  dark: false,
  colors: {
    background: '#f1f5f9', // bg-slate-100
    surface: '#fff',
    'surface-light': '#fff',
  },
  variables: {
    'background': '#f1f5f9', // bg-slate-100
    'shadow-key-umbra-opacity': 'rgba(7,89,133, 0.1)',
    'shadow-key-penumbra-opacity': 'rgba(7,89,133, 0.05)',
    'shadow-key-ambient-opacity': 'rgba(7,89,133, 0.05)',
  }
}

const darkTheme = {
  dark: true,
  colors: {
    background: '#1e293b', // bg-slate-800
    surface: '#0f172a', // bg-slate-900
    'surface-light': '#0f172a', // bg-slate-900
  },
  variables: {
    'background': '#1e293b', // bg-slate-800
    'shadow-key-umbra-opacity': 'rgba(186,230,253, 0.05)',
    'shadow-key-penumbra-opacity': 'rgba(186,230,253, 0.05)',
    'shadow-key-ambient-opacity': 'rgba(186,230,253, 0.05)',

  }
}

export default defineNuxtPlugin((app) => {
  const vuetify = createVuetify({
    locale : {
      locale: 'zhHans',
      fallback: 'sv',
      messages: { zhHans, pl  },
    },
    ssr: true,
    theme: {
      themes: {
        light: LightTheme,
        dark : darkTheme,
      },
    },
  })

  app.vueApp.use(vuetify)
})
